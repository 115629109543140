import React from "react"

import Seo from "../components/Seo"
import SiteHeader from "../components/SiteHeader"
import GenericHero from "../components/GenericHero"
import TopImageContainer from "../components/TopImageContainer"
import SiteFooter from "../components/SiteFooter"

import WorkServicesSection from "../components/Work/WorkServicesSection"
import WorkClientIntroSection from "../components/Work/WorkClientIntroSection"
import WorkClientBodySection from "../components/Work/WorkClientBodySection"

import TopImage from "../images/finelite_hero_bg.png"

import content from "../data/Content"

export default function WorkFinelite() {
  let page = "work-finelite"
  let data = content(page)
  return (
    <div className="content">
      <Seo page={content(page)} />
      <TopImageContainer image={TopImage}>
        <SiteHeader page={page} content={content("header")} />
        <GenericHero content={data} />
      </TopImageContainer>
      <WorkServicesSection content={data} />
      <WorkClientIntroSection content={data} />
      <WorkClientBodySection content={data} />
      <SiteFooter content={content("footer")} />
    </div>
  )
}
